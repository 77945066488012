import { Button, Radio, RadioGroup, Text } from '@adobe/react-spectrum'
import { useMemo, useState } from 'react'
import { useMedia } from 'react-use'
import { Motor } from '../types/motor'

interface MotorSelectProps {
  motors: Motor[]
  value: number
  onChange: React.Dispatch<React.SetStateAction<number>>
}

export default function MotorSelect({
  motors,
  value,
  onChange,
}: MotorSelectProps) {
  const isMobile = useMedia('(max-width: 1024px)')
  const [seeAll, setSeeAll] = useState(false)
  const visibleMotors = useMemo(
    () => (seeAll || !isMobile ? motors : motors.slice(0, 3)),
    [seeAll, motors, isMobile]
  )

  return (
    <>
      {!visibleMotors.length ? <Text>Sem motores disponíveis</Text> : null}
      <RadioGroup
        aria-label='Selecione um motor'
        value={String(value)}
        onChange={(newValue) => onChange(Number(newValue))}
      >
        {visibleMotors.map((motor, index) => (
          <Radio key={motor.id} value={String(index)}>
            {motor.potenciaHp} cv {index === 0 ? '(recomendado)' : null}
          </Radio>
        ))}
      </RadioGroup>
      {isMobile && motors.length > 3 ? (
        <Button
          variant='secondary'
          onPress={() => setSeeAll((old) => !old)}
          marginTop='size-125'
        >
          <Text>{seeAll ? 'Ver menos' : 'Ver mais'}</Text>
        </Button>
      ) : null}
    </>
  )
}
