import { useMemo } from 'react'
import { Motor } from '../types/motor'
import { Settings } from '../types/settings'

const empty = {
  frequenciaEletricaDeOperacaoDoMotor: NaN,
  velocidadeAngularDaCarga: NaN,
  potenciaNominalDaCarga: NaN,
  velocidadeDoMotor: NaN,
  velocidadeAngularDoMotor: NaN,
  conjugadoNominalDaCargaVistaNoEixoDoMotor: NaN,
  polos: NaN,
  potenciaNominalInicialDoMotorWatts: NaN,
  potenciaNominalInicialDoMotorCavalos: NaN,
}

export default function useCompatibleMotorsSpecification(
  settings: Settings,
  motors: Motor[]
) {
  const initialSpecification = useMemo(() => {
    const velocidadeNominalDaCarga = settings.velocidadeNominalDaCarga!
    const conjugadoNominalDaCarga = settings.conjugadoNominalDaCarga!
    const relacaoDeTransmissao = settings.relacaoDeTransmissao!
    const rendimentoDaTransmissao = settings.rendimentoDaTransmissao!

    if (
      !isFinite(velocidadeNominalDaCarga!) ||
      !isFinite(conjugadoNominalDaCarga!) ||
      !isFinite(relacaoDeTransmissao!) ||
      !isFinite(rendimentoDaTransmissao!)
    ) {
      return empty
    }

    const frequenciaEletricaDeOperacaoDoMotor = 60
    const velocidadeAngularDaCarga =
      (2 * Math.PI * velocidadeNominalDaCarga) / 60
    const potenciaNominalDaCarga =
      velocidadeAngularDaCarga * conjugadoNominalDaCarga
    const velocidadeDoMotor = velocidadeNominalDaCarga * relacaoDeTransmissao
    const velocidadeAngularDoMotor =
      velocidadeAngularDaCarga * relacaoDeTransmissao
    const conjugadoNominalDaCargaVistaNoEixoDoMotor =
      conjugadoNominalDaCarga /
      ((rendimentoDaTransmissao / 100) * relacaoDeTransmissao)
    const polos = Math.floor(
      (120 * frequenciaEletricaDeOperacaoDoMotor) / velocidadeDoMotor
    )
    const polosNormalizado = Math.max(Math.min(Math.floor(polos / 2) * 2, 8), 2)
    const potenciaNominalInicialDoMotorWatts =
      potenciaNominalDaCarga / (rendimentoDaTransmissao / 100)
    const potenciaNominalInicialDoMotorCavalos =
      potenciaNominalInicialDoMotorWatts / 736

    return {
      frequenciaEletricaDeOperacaoDoMotor,
      velocidadeAngularDaCarga,
      potenciaNominalDaCarga,
      velocidadeDoMotor,
      velocidadeAngularDoMotor,
      conjugadoNominalDaCargaVistaNoEixoDoMotor,
      polos: polosNormalizado,
      potenciaNominalInicialDoMotorWatts,
      potenciaNominalInicialDoMotorCavalos,
    }
  }, [settings])

  const sortedMotors = useMemo(
    () => motors.sort((a, b) => a.potenciaHp - b.potenciaHp),
    [motors]
  )

  const compatibleMotors = useMemo(
    () =>
      isFinite(initialSpecification.polos) &&
      isFinite(initialSpecification.potenciaNominalInicialDoMotorCavalos)
        ? sortedMotors.filter(
            (motor) =>
              motor.polos === initialSpecification.polos &&
              motor.potenciaHp >=
                initialSpecification.potenciaNominalInicialDoMotorCavalos
          )
        : [],
    [
      initialSpecification.polos,
      initialSpecification.potenciaNominalInicialDoMotorCavalos,
      sortedMotors,
    ]
  )

  return { ...initialSpecification, compatibleMotors }
}
