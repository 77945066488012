import { View, Text, Heading } from '@adobe/react-spectrum'

const formatter = new Intl.NumberFormat('pt-BR')

interface NumberViewProps {
  label: string
  value: number | undefined | null
  unit?: string
  noData?: string
  engineeringNotation?: boolean
}

export default function NumberView({
  label,
  value,
  unit,
  engineeringNotation = true,
  noData = 'Sem informação',
}: NumberViewProps) {
  const isValidValue = typeof value === 'number' && isFinite(value)

  const tenPow =
    engineeringNotation && isValidValue
      ? Math.floor((String(Math.floor(value as number)).length - 1) / 3) * 3
      : 0
  const finalValue = isValidValue
    ? (value as number) / Math.pow(10, tenPow)
    : null
  const formattedValue =
    finalValue !== null ? formatter.format(finalValue) : null

  return (
    <View>
      <Text>{label}</Text>
      <br />
      <View>
        <Heading level={3} marginTop={0}>
          {isValidValue ? (
            <>
              {formattedValue}{' '}
              {tenPow ? (
                <>
                  * 10<sup>{tenPow}</sup>
                </>
              ) : null}{' '}
              <small>{unit}</small>
            </>
          ) : (
            <i>{noData}</i>
          )}
        </Heading>
      </View>
    </View>
  )
}
