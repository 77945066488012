export interface Motor {
  id: number
  potenciaKW: number
  potenciaHp: number
  carcaca: string
  conjugadoNominal: number
  correnteComRotorBloqueado: number
  conjugadoComRotorBloqueado: number
  conjugadoMaximo: number
  momentoDeInercia: number
  tempoMaximoComRotorBloqueadoQuente: number
  tempoMaximoComRotorBloqueadoFrio: number
  peso: number
  nivelMedioDePressaoSonora: number
  fatorDeServico: number
  rpm: number
  porcentagemDeCargaRendimento50: number
  porcentagemDeCargaRendimento75: number
  porcentagemDeCargaRendimento100: number
  porcentagemDeCargaFatorDePotencia50: number
  porcentagemDeCargaFatorDePotencia75: number
  porcentagemDeCargaFatorDePotencia100: number
  correnteNominal: number
  polos: number
}

export const motorPropertiesAndLabels = {
  potenciaKW: 'Potência kW',
  potenciaHp: 'Potência HP',
  carcaca: 'Carcaça',
  conjugadoNominal: 'Conjugado nominal',
  correnteComRotorBloqueado: 'Corrente com rotor bloqueado',
  conjugadoComRotorBloqueado: 'Conjugado com rotor bloqueado',
  conjugadoMaximo: 'Conjugado máximo',
  momentoDeInercia: 'Momento de inércia',
  tempoMaximoComRotorBloqueadoQuente: 'Tempo máximo com rotor bloqueado quente',
  tempoMaximoComRotorBloqueadoFrio: 'Tempo máximo com rotor bloqueado frio',
  peso: 'Peso',
  nivelMedioDePressaoSonora: 'Nível médio de pressão sonora',
  fatorDeServico: 'Fator de serviço',
  rpm: 'RPM',
  porcentagemDeCargaRendimento50: 'Porcentagem de carga - rendimento 50',
  porcentagemDeCargaRendimento75: 'Porcentagem de carga - rendimento 75',
  porcentagemDeCargaRendimento100: 'Porcentagem de carga - rendimento 100',
  porcentagemDeCargaFatorDePotencia50:
    'Porcentagem de carga - fator de potência 50',
  porcentagemDeCargaFatorDePotencia75:
    'Porcentagem de carga - fator de potência 75',
  porcentagemDeCargaFatorDePotencia100:
    'Porcentagem de carga - fator de potência 100',
  correnteNominal: 'Corrente nominal',
  polos: 'Polos',
}
