import { Flex, NumberField, View } from '@adobe/react-spectrum'
import { SpectrumNumberFieldProps } from '@react-types/numberfield'

export interface EnhancedNumberInputProps extends SpectrumNumberFieldProps {
  prepend?: string
  append?: string
}

export default function EnhancedNumberInput({
  append,
  prepend,
  ...props
}: EnhancedNumberInputProps) {
  return (
    <Flex alignItems='end'>
      {prepend ? (
        <Flex height={32} alignItems='center'>
          <View paddingEnd={10}>{prepend}</View>
        </Flex>
      ) : (
        <></>
      )}
      <NumberField
        formatOptions={{
          notation: 'standard',
        }}
        flex
        {...props}
      />
      {append ? (
        <Flex height={32} alignItems='center'>
          <View paddingStart={10}>{append}</View>
        </Flex>
      ) : (
        <></>
      )}
    </Flex>
  )
}
