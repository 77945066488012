import {
  defaultTheme,
  Provider,
  Content,
  Flex,
  Heading,
} from '@adobe/react-spectrum'
import { useEffect, useState } from 'react'
import SettingsForm from './components/settingsForm'
import Motors from './components/motors'
import { Motor } from './types/motor'
import { Settings } from './types/settings'
import useCompatibleMotorsSpecification from './hooks/useCompatibleMotorsSpecification'
import MotorSelect from './components/motorSelect'
import useFinalSpecifications from './hooks/finalSpecifications'
import Results from './components/results'

function App() {
  const [settings, setSettings] = useState<Settings>({
    velocidadeNominalDaCarga: NaN,
    conjugadoNominalDaCarga: NaN,
    conjugadoDePartidaDaCarga: NaN,
    momentoDeInerciaDaCarga: NaN,
    relacaoDeTransmissao: NaN,
    rendimentoDaTransmissao: NaN,
    momentoDeInerciaDaTransmissao: NaN,
    // velocidadeNominalDaCarga: 440,
    // conjugadoNominalDaCarga: 320,
    // conjugadoDePartidaDaCarga: 32,
    // momentoDeInerciaDaCarga: 20,
    // relacaoDeTransmissao: 4,
    // rendimentoDaTransmissao: 90,
    // momentoDeInerciaDaTransmissao: 0.1,
  })
  const [selectedMotorIndex, setSelectedMotorIndex] = useState(0)
  const [motors, setMotors] = useState<Motor[]>([])
  const compatibleMotorsSpecification = useCompatibleMotorsSpecification(
    settings,
    motors
  )
  const motor: Motor | undefined =
    compatibleMotorsSpecification.compatibleMotors[selectedMotorIndex]
  const finalSpecifications = useFinalSpecifications(
    settings,
    compatibleMotorsSpecification,
    motor
  )

  useEffect(() => {
    setSelectedMotorIndex(0)
  }, [compatibleMotorsSpecification.compatibleMotors[0]?.id])

  return (
    <Provider theme={defaultTheme} colorScheme='dark' flex>
      <Flex direction={{ base: 'column', L: 'row' }} gap='size-100'>
        <Content flex='1' marginX='1rem'>
          <Heading level={2}>Configurações</Heading>
          <Motors value={motors} onChange={setMotors} />
          <SettingsForm value={settings} onChange={setSettings}></SettingsForm>
        </Content>
        <Content flex='1' marginX='1rem'>
          <Heading level={2}>Motores compatíveis</Heading>
          <MotorSelect
            motors={compatibleMotorsSpecification.compatibleMotors}
            value={selectedMotorIndex}
            onChange={setSelectedMotorIndex}
          />
        </Content>
        <Content flex='1' marginX='1rem'>
          <Heading level={2}>Resultados</Heading>
          <Results specifications={finalSpecifications} motor={motor} />
        </Content>
      </Flex>
    </Provider>
  )
}

export default App
