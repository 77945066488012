import { Form } from '@adobe/react-spectrum'
import { Settings } from '../types/settings'
import EnhancedNumberInput from './enhancedNumberInput'

interface SettingsFormProps {
  value: Settings
  onChange: React.Dispatch<React.SetStateAction<Settings>>
}

export default function SettingsForm({ value, onChange }: SettingsFormProps) {
  return (
    <Form>
      <EnhancedNumberInput
        label='Velocidade nominal da carga'
        append='RPM'
        maxWidth='size-2000'
        minValue={0.01}
        step={0.01}
        value={value.velocidadeNominalDaCarga}
        onChange={(value) =>
          onChange((oldValue) => ({
            ...oldValue,
            velocidadeNominalDaCarga: value,
          }))
        }
      />

      <EnhancedNumberInput
        label='Conjugado nominal da carga'
        append='N.m'
        maxWidth='size-2000'
        minValue={0.01}
        step={0.01}
        value={value.conjugadoNominalDaCarga}
        onChange={(value) =>
          onChange((oldValue) => ({
            ...oldValue,
            conjugadoNominalDaCarga: value,
            conjugadoDePartidaDaCarga: value * 0.1,
          }))
        }
      />

      <EnhancedNumberInput
        label='Conjugado de partida da carga'
        append='N.m'
        maxWidth='size-2000'
        minValue={0.01}
        step={0.01}
        value={value.conjugadoDePartidaDaCarga}
        isReadOnly
      />

      <EnhancedNumberInput
        label='Momento de inércia da carga'
        append='kg.m²'
        maxWidth='size-2000'
        minValue={0.01}
        step={0.01}
        value={value.momentoDeInerciaDaCarga}
        onChange={(value) =>
          onChange((oldValue) => ({
            ...oldValue,
            momentoDeInerciaDaCarga: value,
          }))
        }
      />

      <EnhancedNumberInput
        label='Relação de transmissão'
        append='z'
        maxWidth='size-2000'
        minValue={1}
        step={1}
        value={value.relacaoDeTransmissao}
        onChange={(value) =>
          onChange((oldValue) => ({
            ...oldValue,
            relacaoDeTransmissao: value,
          }))
        }
      />

      <EnhancedNumberInput
        label='Rendimento de transmissão'
        append='η%'
        maxWidth='size-2000'
        minValue={0.01}
        step={0.01}
        value={value.rendimentoDaTransmissao}
        onChange={(value) =>
          onChange((oldValue) => ({
            ...oldValue,
            rendimentoDaTransmissao: value,
          }))
        }
      />

      <EnhancedNumberInput
        label='Momento de inércia da transmissão'
        append='kg.m²'
        maxWidth='size-2000'
        minValue={0.01}
        step={0.01}
        value={value.momentoDeInerciaDaTransmissao}
        onChange={(value) =>
          onChange((oldValue) => ({
            ...oldValue,
            momentoDeInerciaDaTransmissao: value,
          }))
        }
      />
    </Form>
  )
}
