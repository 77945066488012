import {
  ActionButton,
  Button,
  ButtonGroup,
  Content,
  Dialog,
  DialogContainer,
  Divider,
  Header,
  Heading,
  Text,
  View,
} from '@adobe/react-spectrum'
import { useRef, useState } from 'react'
import XLSX from 'xlsx'
import { Motor, motorPropertiesAndLabels } from '../types/motor'

interface MotorsProps {
  value: Motor[]
  onChange: React.Dispatch<React.SetStateAction<Motor[]>>
}

const propertiesKeys = Object.keys(motorPropertiesAndLabels)

export default function Motors({ value, onChange }: MotorsProps) {
  let [isOpen, setIsOpen] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleSelectFile = async (file: File) => {
    const reader = new FileReader()
    reader.onload = (event) => {
      if (!event.target) {
        return
      }
      try {
        const arrayBuffer = event.target.result
        const workBook = XLSX.read(arrayBuffer, { type: 'array', raw: true })
        const sheetName = workBook.SheetNames[0]
        const worksheet = workBook.Sheets[sheetName]
        const data = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
          rawNumbers: true,
          raw: true,
        })
        const initialIndex = data.findIndex((row) => {
          const potenciaKW = (row as any[])[0]
          if (typeof potenciaKW === 'undefined' || potenciaKW === null) {
            return false
          }
          if (typeof potenciaKW === 'number') {
            return true
          }
          return (
            potenciaKW.trim().length &&
            isFinite(potenciaKW.trim().replace(',', '.'))
          )
        })

        const motors = data.slice(initialIndex).map((row, id) =>
          (row as any[]).reduce((motor, value, index) => {
            const safeString = String(value).trim().replace(',', '.')
            const normalizedValue = isFinite(safeString as any)
              ? Number(safeString)
              : safeString
            return {
              id,
              ...motor,
              [propertiesKeys[index]]:
                typeof normalizedValue === 'number' && isNaN(normalizedValue)
                  ? null
                  : normalizedValue,
            }
          }, {})
        )
        onChange(motors)
      } catch (error) {
        setError(error as Error)
      }
    }
    reader.onerror = () => setError(reader.error)
    reader.readAsArrayBuffer(file)
  }

  const handleLoadExample = async () => {
    const response = await fetch('/motors.csv')
    const blob = await response.blob()
    const file = new File([blob], 'motors.csv', { type: blob.type })
    handleSelectFile(file)
  }

  const handleLoadFile = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  return (
    <>
      <View marginBottom='size-300'>
        <Button onPress={() => setIsOpen(true)} variant='cta'>
          <Text>
            {value.length ? `${value.length} motores` : 'Selecionar motores'}
          </Text>
        </Button>
      </View>
      <DialogContainer onDismiss={() => setIsOpen(false)}>
        {isOpen && (
          <Dialog>
            <Heading>Motores</Heading>
            <Header>{value.length}</Header>
            <Divider />
            <Content>
              <View marginBottom='size-100'>
                <input
                  type='file'
                  accept='.csv,.xlsx,.xls'
                  placeholder='Selecione o arquivo CSV ou XLSX'
                  onChange={(event) =>
                    event.target.files?.length
                      ? handleSelectFile(event.target.files[0])
                      : undefined
                  }
                  ref={inputRef}
                  hidden
                />
              </View>

              <View marginBottom='size-100'>
                <ButtonGroup>
                  <Button variant='secondary' onPress={handleLoadFile}>
                    Carregar arquivo
                  </Button>

                  <Button variant='secondary' onPress={handleLoadExample}>
                    Carregar exemplo
                  </Button>

                  <Button
                    elementType='a'
                    variant='secondary'
                    href='/motors.csv'
                  >
                    Baixar exemplo
                  </Button>
                </ButtonGroup>
              </View>

              {error ? (
                <View marginBottom='size-100'>
                  <Text>{error.message}</Text>
                </View>
              ) : null}

              {value.length ? (
                <View overflow='scroll' height='size-3000'>
                  <table aria-label='Motores'>
                    <thead>
                      <tr>
                        {propertiesKeys.map((key) => (
                          <th key={key}>
                            {
                              motorPropertiesAndLabels[
                                key as keyof typeof motorPropertiesAndLabels
                              ]
                            }
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {value.map((motor, index) => (
                        <tr key={index}>
                          {propertiesKeys.map((key, index) => (
                            <td key={key}>
                              {
                                motor[
                                  key as keyof typeof motorPropertiesAndLabels
                                ]
                              }
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </View>
              ) : null}
            </Content>
            <ButtonGroup>
              <Button variant='cta' onPress={() => setIsOpen(false)}>
                Ok
              </Button>
            </ButtonGroup>
          </Dialog>
        )}
      </DialogContainer>
    </>
  )
}
