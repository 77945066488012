import { useMemo } from 'react'
import { Motor } from '../types/motor'
import { Settings } from '../types/settings'
import type useCompatibleMotorsSpecification from './useCompatibleMotorsSpecification'

const empty = {
  conjugadoMedioDoMotor: NaN,
  conjugadoMedioDaCarga: NaN,
  conjugadoMedioDaCargaParaEixoDoMotor: NaN,
  momentoDeInercia: NaN,
  momentoDeInerciaDaTransmissao: NaN,
  tempoMaximoComRotorBloqueadoQuente: NaN,
  momentoDeInerciaDaCargaAoEixoDoMotor: NaN,
  tempoDeAceleracao: NaN,
  tempoDeAceleracaoLimite: NaN,
  avaliacao: null,
}

export default function useFinalSpecifications(
  settings: Settings,
  preliminarSpecification: ReturnType<typeof useCompatibleMotorsSpecification>,
  motor: Motor | undefined
) {
  const finalSpecifications = useMemo(() => {
    const velocidadeNominalDaCarga = settings.velocidadeNominalDaCarga!
    const conjugadoNominalDaCarga = settings.conjugadoNominalDaCarga!
    const relacaoDeTransmissao = settings.relacaoDeTransmissao!
    const rendimentoDaTransmissao = settings.rendimentoDaTransmissao!
    const conjugadoDePartidaDaCarga = settings.conjugadoDePartidaDaCarga!
    const momentoDeInerciaDaTransmissao =
      settings.momentoDeInerciaDaTransmissao!
    const momentoDeInerciaDaCarga = settings.momentoDeInerciaDaCarga!
    const velocidadeDoMotor = preliminarSpecification.velocidadeDoMotor!

    if (
      !isFinite(velocidadeNominalDaCarga!) ||
      !isFinite(conjugadoNominalDaCarga!) ||
      !isFinite(relacaoDeTransmissao!) ||
      !isFinite(rendimentoDaTransmissao!) ||
      !isFinite(momentoDeInerciaDaTransmissao!) ||
      !isFinite(momentoDeInerciaDaCarga!) ||
      !isFinite(velocidadeDoMotor!) ||
      !motor
    ) {
      return { ...preliminarSpecification, ...empty }
    }

    const conjugadoMedioDoMotor =
      0.45 *
      (motor.conjugadoComRotorBloqueado + motor.conjugadoMaximo) *
      motor.conjugadoNominal *
      9.81

    const conjugadoMedioDaCarga =
      (2 * conjugadoDePartidaDaCarga + conjugadoNominalDaCarga) / 3
    const conjugadoMedioDaCargaParaEixoDoMotor =
      conjugadoMedioDaCarga / relacaoDeTransmissao

    const momentoDeInercia = motor.momentoDeInercia
    const tempoMaximoComRotorBloqueadoQuente =
      motor.tempoMaximoComRotorBloqueadoQuente
    const momentoDeInerciaDaCargaAoEixoDoMotor =
      momentoDeInerciaDaCarga / Math.pow(relacaoDeTransmissao, 2)
    const tempoDeAceleracao =
      ((velocidadeDoMotor * 2 * Math.PI) / 60) *
      ((momentoDeInercia +
        momentoDeInerciaDaTransmissao +
        momentoDeInerciaDaCargaAoEixoDoMotor) /
        (conjugadoMedioDoMotor - conjugadoMedioDaCargaParaEixoDoMotor))
    const tempoDeAceleracaoLimite = 0.8 * tempoMaximoComRotorBloqueadoQuente
    const avaliacao =
      tempoDeAceleracaoLimite >= tempoDeAceleracao
        ? 'O motor especificado partirá a carga'
        : 'O motor especificado NÃO partirá a carga'

    return {
      ...preliminarSpecification,
      conjugadoMedioDoMotor,
      conjugadoMedioDaCarga,
      conjugadoMedioDaCargaParaEixoDoMotor,
      momentoDeInercia,
      momentoDeInerciaDaTransmissao,
      tempoMaximoComRotorBloqueadoQuente,
      momentoDeInerciaDaCargaAoEixoDoMotor,
      tempoDeAceleracao,
      tempoDeAceleracaoLimite,
      avaliacao,
    }
  }, [settings, preliminarSpecification, motor])

  return finalSpecifications
}
