import { Heading, Text, View } from '@adobe/react-spectrum'
import type useFinalSpecifications from '../hooks/finalSpecifications'
import { Motor } from '../types/motor'
import NumberView from './numberView'

interface ResultsProps {
  specifications: ReturnType<typeof useFinalSpecifications>
  motor: Motor | undefined
}

export default function Results({ specifications, motor }: ResultsProps) {
  return (
    <>
      <NumberView
        label='Velocidade angular da carga'
        value={specifications.velocidadeAngularDaCarga}
        unit='rad/s'
      />
      <NumberView
        label='Potência da nominal da carga'
        value={specifications.potenciaNominalDaCarga}
        unit='W'
      />
      <NumberView
        label='Velocidade do motor'
        value={specifications.velocidadeDoMotor}
        unit='RPM'
      />
      <NumberView
        label='Velocidade angular do motor'
        value={specifications.velocidadeAngularDoMotor}
        unit='rad/s'
      />
      <NumberView
        label='Conjugado nominal da carga vista no eixo do motor'
        value={specifications.conjugadoNominalDaCargaVistaNoEixoDoMotor}
        unit='N.m'
      />
      <NumberView label='Polos' value={specifications.polos} />
      <NumberView
        label='Potência nominal inicial do motor'
        value={specifications.potenciaNominalInicialDoMotorWatts}
        unit='W'
      />
      <NumberView
        label='Potência nominal inicial do motor'
        value={specifications.potenciaNominalInicialDoMotorCavalos}
        unit='cv'
      />
      <NumberView
        label='Conjugado médio do motor'
        value={specifications.conjugadoMedioDoMotor}
        unit='N.m'
      />
      <NumberView
        label='Conjugado médio da carga'
        value={specifications.conjugadoMedioDaCarga}
        unit='N.m'
      />
      <NumberView
        label='Conjugado médio da carga para o eixo do motor'
        value={specifications.conjugadoMedioDaCargaParaEixoDoMotor}
        unit='N.m'
      />
      <NumberView
        label='Momento de inércia do motor'
        value={motor?.momentoDeInercia}
        unit='kg.m²'
      />
      <NumberView
        label='Momento de inércia da carga ao eixo do motor'
        value={specifications.momentoDeInerciaDaCargaAoEixoDoMotor}
        unit='kg.m²'
      />
      <NumberView
        label='Tempo de aceleração'
        value={specifications.tempoDeAceleracao}
        unit='s'
      />
      <NumberView
        label='Tempo de rotor bloqueado (quente)'
        value={motor?.tempoMaximoComRotorBloqueadoQuente}
        unit='s'
      />
      <NumberView
        label='Tempo de aceleração limite'
        value={specifications.tempoDeAceleracaoLimite}
        unit='s'
      />
      <View>
        <Text>Carcaça</Text>
        <br />
        <View>
          <Heading level={3} marginTop={0}>
            {motor?.carcaca ? motor.carcaca : <i>Sem informação</i>}
          </Heading>
        </View>
      </View>
      <View>
        <Text>Avaliação</Text>
        <br />
        <View>
          <Heading level={3} marginTop={0}>
            {specifications.avaliacao ? (
              <>{specifications.avaliacao}</>
            ) : (
              <i>Sem informação</i>
            )}
          </Heading>
        </View>
      </View>
    </>
  )
}
